const clearedMessageList = [
  'デカいよ',
  'キレてる',
  '冷蔵庫',
  'ナイスバルク',
  '仕上がってるよ',
  '土台が違うよ、土台が',
  'デカいよ！他が見えない',
  'そこまで絞るには眠れない夜もあったろう',
];

export default clearedMessageList;
